import React from "react";
import "./opc.css";

const OPCRegistration = () => {
  return (
    <div className="opc-container">
      <h1 className="opc-title">One Person Company (OPC) Registration</h1>
      <p className="opc-description">
        A **One Person Company (OPC)** allows a single entrepreneur to operate a corporate entity with limited liability protection.
      </p>

      {/* Benefits Section */}
      <section className="opc-section">
        <h2>🚀 Key Benefits of OPC</h2>
        <div className="opc-benefits">
          <div className="opc-card">✔️ Limited Liability</div>
          <div className="opc-card">✔️ Sole Ownership</div>
          <div className="opc-card">✔️ Easy Compliance</div>
          <div className="opc-card">✔️ Separate Legal Entity</div>
        </div>
      </section>

      {/* Registration Process Section */}
      <section className="opc-section">
        <h2>📜 Registration Process</h2>
        <ul className="opc-process">
          <li>📝 Obtain Digital Signature Certificate (DSC)</li>
          <li>📌 Apply for Director Identification Number (DIN)</li>
          <li>🏛️ Name Approval from MCA</li>
          <li>📄 File Incorporation Documents</li>
          <li>✔️ Obtain Certificate of Incorporation</li>
        </ul>
      </section>

      {/* Documents Required Section */}
      <section className="opc-section">
        <h2>📑 Required Documents</h2>
        <div className="opc-documents">
          <div className="doc-card">🆔 PAN & Aadhar Card</div>
          <div className="doc-card">🏠 Business Address Proof</div>
          <div className="doc-card">📄 Memorandum & Articles of Association</div>
          <div className="doc-card">🏦 Bank Statement</div>
        </div>
      </section>

      {/* Compliance Requirements */}
      <section className="opc-section">
        <h2>⚖️ Compliance Requirements</h2>
        <ul className="opc-compliance">
          <li>📌 Annual ROC Filings</li>
          <li>📌 Income Tax Returns</li>
          <li>📌 GST Filing (if applicable)</li>
          <li>📌 Financial Audits</li>
        </ul>
      </section>
    </div>
  );
};

export default OPCRegistration;
