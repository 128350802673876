import React from "react";
import "./LLPreg.css";

const LLPRegistration = () => {
  return (
    <div className="llp-container">
      <h1>LLP Registration</h1>
      <p>
        Limited Liability Partnership (LLP) is a business structure that provides limited liability 
        to its owners while allowing operational flexibility similar to a partnership.
      </p>

      <section className="llp-section">
        <h2>Why Choose LLP?</h2>
        <ul>
          <li>Limited liability protection</li>
          <li>Separate legal entity</li>
          <li>Less compliance compared to a Private Limited Company</li>
          <li>No minimum capital requirement</li>
          <li>Easy to manage and transfer ownership</li>
        </ul>
      </section>

      <section className="llp-section">
        <h2>LLP Registration Process</h2>
        <ol>
          <li>Obtain Digital Signature Certificate (DSC) for partners</li>
          <li>Apply for Director Identification Number (DIN)</li>
          <li>Reserve LLP Name through MCA portal</li>
          <li>File Incorporation Form with necessary documents</li>
          <li>Obtain Certificate of Incorporation</li>
          <li>Apply for PAN and TAN</li>
          <li>Draft and file LLP Agreement</li>
        </ol>
      </section>

      <section className="llp-section">
        <h2>Documents Required</h2>
        <ul>
          <li>PAN card of partners</li>
          <li>Aadhar card or address proof</li>
          <li>Passport-size photographs</li>
          <li>Electricity bill or rental agreement of registered office</li>
          <li>Digital Signature Certificate (DSC)</li>
        </ul>
      </section>

      <section className="llp-section">
        <h2>LLP Compliance</h2>
        <p>LLPs must comply with annual filings and tax requirements such as:</p>
        <ul>
          <li>Filing of Annual Return (Form 11)</li>
          <li>Statement of Accounts and Solvency (Form 8)</li>
          <li>Income Tax Return Filing</li>
        </ul>
      </section>
    </div>
  );
};

export default LLPRegistration;
