import React, { useEffect, useState } from "react";
import "./style.css";
import { FaLaptopCode, FaBullhorn, FaEnvelopeOpenText, FaShieldAlt, FaGoogle, FaUsers, FaPaintBrush, FaSearch } from "react-icons/fa";

import Contact from "../../component/Contact/Contact";
import NetworkPartners from "../../component/NetworkPartners/NetworkPartners";

const ITServices = () => {
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".service-card");
      elements.forEach((el) => {
        const position = el.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (position < windowHeight - 100) {
          el.classList.add("animate");
        } else {
          el.classList.remove("animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const services = [
    {
      icon: <FaPaintBrush size={40} className="icon pink" />, 
      title: "Graphics & Logo Design", 
      description: "We create stunning graphics and unique logo designs that represent your brand identity effectively.",
      price: "₹5,000 - ₹20,000"
    },
    {
      icon: <FaSearch size={40} className="icon blue" />, 
      title: "SEO", 
      description: "Improve your search engine rankings and drive organic traffic with our expert SEO services.",
      price: "₹10,000 - ₹50,000"
    },
    {
      icon: <FaLaptopCode size={40} className="icon blue" />,
      title: "Website Development",
      description: "We build high-performance, responsive websites tailored to your business needs.",
      price: "₹15,000 - ₹1,00,000"
    },
    {
      icon: <FaBullhorn size={40} className="icon green" />,
      title: "Digital Marketing",
      description: "Boost your online presence with our expert digital marketing strategies.",
      price: "₹8,000 - ₹60,000"
    },
    {
      icon: <FaEnvelopeOpenText size={40} className="icon yellow" />,
      title: "Email Marketing",
      description: "Engage your customers with tailored email campaigns to boost conversions.",
      price: "₹5,000 - ₹30,000"
    },
    {
      icon: <FaShieldAlt size={40} className="icon red" />,
      title: "SSL Certificate",
      description: "Secure your website and build trust with an SSL certificate.",
      price: "₹2,000 - ₹10,000"
    },
    {
      icon: <FaGoogle size={40} className="icon purple" />,
      title: "Google Leads",
      description: "Generate high-quality leads with targeted Google Ads strategies.",
      price: "₹10,000 - ₹1,00,000"
    },
    {
      icon: <FaUsers size={40} className="icon orange" />,
      title: "Social Media Management",
      description: "Manage and grow your social media presence with creative content and campaigns.",
      price: "₹7,000 - ₹40,000"
    }
  ];

  return (
    <div className="service-container">
      <h1 className="service-heading">Deenis Law Chamber for IT Services</h1>
      <p className="service-subheading">
        We offer a range of IT services to help businesses establish and grow their online presence. From professional website development to effective digital marketing and SEO strategies, we ensure your success in the digital world.
      </p>
      <h2 className="title">Our Services</h2>
      <div className="service-grid">
        {services.map((service, index) => (
          <div 
            key={index} 
            className="service-card" 
            onClick={() => setSelectedService(service)}
          >
            <div className="icon-container">{service.icon}</div>
            <h2 className="service-title">{service.title}</h2>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>

      {selectedService && (
        <div className="modal-overlay" onClick={() => setSelectedService(null)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-btn" onClick={() => setSelectedService(null)}>&times;</span>
            <div className="icon-container">{selectedService.icon}</div>
            <h2 className="modal-title">{selectedService.title}</h2>
            <p className="modal-description">{selectedService.description}</p>
            <p className="modal-price"><strong>Price:</strong> {selectedService.price}</p>
          </div>
        </div>
      )}

      <p className="additional-info">
        Our team is dedicated to providing innovative and customized solutions for your business needs. We work with you to create strategies that maximize growth and ensure long-term success.
      </p>

      <NetworkPartners />
      <Contact />
    </div>
  );
};

export default ITServices;
