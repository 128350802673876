import React from "react";
import "./style.css";

const EPRCertificate = () => {
  return (
    <div className="epr-container">
      <h1 className="epr-title">EPR Certificate</h1>
      <p className="epr-description">
        Extended Producer Responsibility (EPR) certification ensures compliance with environmental sustainability regulations. It mandates manufacturers and importers to take responsibility for the disposal and recycling of their products.
      </p>
      <h2 className="epr-subtitle">Why is EPR Certification Important?</h2>
      <ul className="epr-list">
        <li>Ensures environmental sustainability.</li>
        <li>Reduces waste and promotes recycling.</li>
        <li>Compliance with government regulations.</li>
        <li>Enhances corporate social responsibility.</li>
      </ul>
      <h2 className="epr-subtitle">How to Apply?</h2>
      <p className="epr-description">
        Businesses need to submit an application with required documents, including business registration, waste management plans, and compliance reports. The process involves evaluation and approval by environmental regulatory bodies.
      </p>
      <h2 className="epr-subtitle">Required Documents</h2>
      <ul className="epr-list">
        <li>Business Registration Certificate</li>
        <li>Waste Management Plan</li>
        <li>Product Lifecycle Assessment Report</li>
        <li>Environmental Compliance Report</li>
      </ul>
    </div>
  );
};

export default EPRCertificate;