import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";

import logo from '../../assets/logo__2_-removebg-preview.png'; // Adjust the path based on where you place the logo

// Function to scroll to top
const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-logo">
                <Link to="/" onClick={scrollToTop}> {/* Click on logo to go to home and scroll to top */}
                    <img src={logo} alt="DLC Logo" className="footer-logo-img" />
                </Link>
            </div>
            
            <div className="footer-sections">
                <div className="footer-column footer-contact">
                    <h3>Contact Us</h3>
                    <p>Phone: <a href="tel:+91926558488">+91 9266558488</a></p>
                    <p>Phone: <a href="tel:+91 9266717139">+91 9266717139</a></p>
                    <p>Email: <a href="mailto:Deenis.group2000@gmail.com">Deenis.group2000@gmail.com</a></p>
                    <p>
                        B 115 ,1st floor ,plot no B-8, <br />GDITL TOWER, NSP, Pitampura, <br /> Delhi -110034
                    </p>
                  
                </div>
                <div className="footer-column">
                    <h3>Online Legal Consultation</h3>
                    <ul>
                     
                        <li><Link to="/employment" onClick={scrollToTop}>Employment Matters</Link></li>
                        <li><Link to="/recovery" onClick={scrollToTop}>Recovery of Money</Link></li>
                       
                        <li><Link to="/blog" onClick={scrollToTop}>Blog</Link></li>
                        <li><Link to="/pressnews" onClick={scrollToTop}>News</Link></li>
                        <li><Link to="/business-license" onClick={scrollToTop}>Business License</Link></li>
                        <li><Link to="/copyrightipr" onClick={scrollToTop}>Copyright</Link></li>
                        <li><Link to="/privacy" onClick={scrollToTop}>Privacy Policy</Link></li>
                       
                        <li><Link to="/tandc" onClick={scrollToTop}>Terms and Conditions</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h3>Other Featured Services</h3>
                    <ul>
                    
                        <li><Link to="/birth" onClick={scrollToTop}>Birth Certificate</Link></li>
                        <li><Link to="/succession" onClick={scrollToTop}>Succession Certificate</Link></li>
                        <li><Link to="/legalheir" onClick={scrollToTop}>Legal Heir Certificate</Link></li>
                        <li><Link to="/namechange" onClick={scrollToTop}>Name Change</Link></li>
                        <li><Link to="/traffic" onClick={scrollToTop}>Traffic Challan</Link></li>
                        <li><Link to="/death" onClick={scrollToTop}>Death Certificate</Link></li>
                        <li><Link to="/DrugLicense" onClick={scrollToTop}>Drug License</Link></li>
                        <li><Link to="/sale" onClick={scrollToTop}>Sale Deed</Link></li>
                       
                    </ul>
                </div>
                
                <div className="footer-column">
                    <h3>Court Marriage Service Cities</h3>
                    <ul>
                        <li><Link to="#" onClick={scrollToTop}>Delhi</Link></li>
                        <li><Link to="#" onClick={scrollToTop}>Gurugram</Link></li>
                        <li><Link to="#" onClick={scrollToTop}>Haryana</Link></li>
                        <li><Link to="#" onClick={scrollToTop}>Lucknow</Link></li>
                        <li><Link to="#" onClick={scrollToTop}>Varanasi</Link></li>
                        <li><Link to="#" onClick={scrollToTop}>Rajasthan</Link></li>
                        <li><Link to="#" onClick={scrollToTop}>Bhopal</Link></li>
                        <li><Link to="#" onClick={scrollToTop}>Patna</Link></li>
                        <li><Link to="#" onClick={scrollToTop}>Agra</Link></li>
                    </ul>
                </div>
            </div>
              {/* Floating Action Buttons */}
      <div className="floating-buttons">
        <a href="tel:+917042032325" className="float-call"><FaPhoneAlt /></a>
        <a href="https://wa.me/919266717139" className="float-whatsapp"><FaWhatsapp /></a>
      </div>

            <div className="footer-bottom">
                <p>Copyright © 2021-2024 Deenis Law Chamber | All Rights Reserved</p>
            </div>
        </footer>
    );
};

export default Footer;
