import React from "react";
import "./style.css"; // Import the CSS file

const TermsConditions = () => {
  return (
    <div className="terms-container">
      <h1 className="terms-title">Terms and Conditions</h1>

      <section className="terms-box">
        <h2>1. Introduction</h2>
        <p>
          Welcome to <strong>deenislawchamber.com</strong> . This
          Website is owned and operated by <strong>Deenis Law Chamber</strong>,
          located at B-115, 1st Floor, Plot No. B-8, GDITL Tower, NSP,
          Pitampura, Delhi - 110034, India. By accessing and using our Website,
          you accept and agree to comply with the following Terms and
          Conditions ("Terms"). If you do not agree with these Terms, you should
          not use our Website.
        </p>
      </section>

      <section className="terms-box">
        <h2>2. Modifications to Terms</h2>
        <p>
          We reserve the right to modify, update, or change these Terms at any
          time without prior notice. Your continued use of the Website after any
          such changes constitutes your acceptance of the revised Terms.
        </p>
      </section>

      <section className="terms-box">
        <h2>3. Use of Website</h2>
        <ul>
          <li>Use the Website only for lawful purposes and in accordance with applicable laws and regulations.</li>
          <li>Provide accurate and complete information when required.</li>
          <li>Not engage in any unauthorized use, duplication, or modification of the Website’s content.</li>
          <li>Not use automated systems or software to extract data from our Website without prior authorization.</li>
        </ul>
      </section>

      <section className="terms-box">
        <h2>4. Intellectual Property Rights</h2>
        <p>
          All content on this Website, including text, graphics, logos, images,
          and software, is the intellectual property of Deenis Law Chamber and
          is protected by copyright, trademark, and other applicable laws.
          Unauthorized use, reproduction, or distribution of any content is
          strictly prohibited.
        </p>
      </section>

      <section className="terms-box">
        <h2>5. Disclaimer of Warranties</h2>
        <p>
          The Website and its content are provided on an "as-is" and
          "as-available" basis without warranties of any kind, either express or
          implied.
        </p>
      </section>

      <section className="terms-box">
        <h2>6. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, Deenis Law Chamber shall not
          be liable for any damages, including but not limited to direct,
          indirect, incidental, consequential, or punitive damages arising from
          your use of the Website or reliance on any information provided
          herein.
        </p>
      </section>

      <section className="terms-box">
        <h2>7. Privacy Policy</h2>
        <p>
          Your privacy is important to us. Please review our Privacy Policy,
          which outlines how we collect, use, and protect your personal
          information.
        </p>
      </section>

      <section className="terms-box">
        <h2>8. Contact Information</h2>
        <p>If you have any questions about these Terms, you may contact us at:</p>
        <p><strong>Phone:</strong> +91 9266558488</p>
        <p><strong>Email:</strong> Deenis.group2000@gmail.com</p>
        <p>
          <strong>Address:</strong> B-115, 1st Floor, Plot No. B-8, GDITL Tower,
          NSP, Pitampura, Delhi - 110034, India
        </p>
      </section>
    </div>
  );
};

export default TermsConditions;
