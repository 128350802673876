import React from "react";
import "./style.css";
import Contact from "../../component/Contact/Contact";

const CopyrightIPR = () => {
  const rights = [
    { title: "Copyright", description: "Protects original works of authorship like books, music, and software." },
    { title: "Trademark", description: "Protects brand names, logos, and slogans used in business." },
    { title: "Patent", description: "Protects new inventions and technological advancements." },
    { title: "Trade Secret", description: "Protects confidential business information like formulas and processes." },
    { title: "Industrial Design", description: "Protects the aesthetic or ornamental aspects of a product." },
    { title: "Sound Recording", description: "Protects recorded sounds, including music tracks, speeches, and other audio works." }
];


  return (
    <div className="copyright-ipr">
      <h1>Copyright & Intellectual Property Rights (IPR)</h1>
      <p>Intellectual Property Rights (IPR) protect the creations of the mind, ensuring exclusive rights to creators.</p>

      <h2>Types of Intellectual Property Rights</h2>
      <div className="ipr-grid">
        {rights.map((right, index) => (
          <div className="ipr-box" key={index}>
            <h3>{right.title}</h3>
            <p>{right.description}</p>
          </div>
        ))}
      </div>

      <div className="ipr-info">
        <h2>Understanding Copyright</h2>
        <p>Copyright is a legal right that gives creators exclusive rights over their work, including books, music, films, and software.</p>
        <p>It allows the owner to control reproduction, distribution, and public display of their work while preventing unauthorized use.</p>

        <h2>How to Obtain Copyright?</h2>
        <p>Copyright is automatically granted upon creation of an original work. However, registration provides stronger legal protection.</p>
        <p>Steps for registration:</p>
        <ul>
          <li>Submit an application to the copyright office.</li>
          <li>Provide a copy of the work and necessary documentation.</li>
          <li>Pay the registration fee and await approval.</li>
        </ul>

        <h2>Enforcement & Infringement</h2>
        <p>If copyright is violated, the owner can take legal action to stop unauthorized use and seek damages. This includes sending legal notices or filing lawsuits.</p>
      </div>
      <Contact/>
    </div>
  );
};

export default CopyrightIPR;
