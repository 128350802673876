import React from "react";
import "./NetworkPartners.css";
import Naturo from "../../assets/imagedoc (1) NATUROCARE.jpg";
import imag from '../../assets/imagedoc  CZAR.jpg';
import kicks from '../../assets/imagedoc KICKS.jpg';
import lysoo from '../../assets/imagedoc lysoo.jpg';
import imaged from '../../assets/imagedoc.jpg';


const NetworkPartners = () => {
  const partners = [
    { id: 1, logo: Naturo }, // Updated to use imported images
    { id: 2, logo: imag },
    { id: 3, logo: kicks },
    { id: 4, logo: lysoo },
    { id: 5, logo: imaged },
  ];

  return (
    <section id="network-partners" className="network-partners-section">
      <h2>Our Network Partners</h2>
      <div className="partner-container">
        {partners.map((partner) => (
          <div key={partner.id} className="partner-logo">
            <img src={partner.logo} alt={`Partner ${partner.id}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default NetworkPartners;
