import React from "react";
import "./TDS.css";

const TdsReturn = () => {
  return (
    <div className="tds-container">
      <h1 className="tds-title">TDS Return Filing</h1>
      <p className="tds-description">
        **TDS (Tax Deducted at Source)** is a tax deduction mechanism where a portion of income is withheld and paid to the government before the income reaches the recipient.
      </p>

      {/* Benefits Section */}
      <section className="tds-section">
        <h2>✅ Benefits of Filing TDS Return</h2>
        <div className="tds-benefits">
          <div className="tds-card">✔️ Compliance with Tax Laws</div>
          <div className="tds-card">✔️ Avoid Interest & Penalties</div>
          <div className="tds-card">✔️ Seamless Refund Claims</div>
          <div className="tds-card">✔️ Prevent Legal Consequences</div>
        </div>
      </section>

      {/* Filing Process Section */}
      <section className="tds-section">
        <h2>📌 TDS Return Filing Process</h2>
        <ul className="tds-process">
          <li>📝 Collect TDS Deduction Details</li>
          <li>💻 Prepare TDS Statement (Form 24Q, 26Q, etc.)</li>
          <li>📩 Validate Statement via FVU Utility</li>
          <li>🚀 Submit TDS Return on TRACES Portal</li>
          <li>✔️ Generate & Download Acknowledgment</li>
        </ul>
      </section>

      {/* Documents Required Section */}
      <section className="tds-section">
        <h2>📑 Required Documents</h2>
        <div className="tds-documents">
          <div className="doc-card">🧾 TAN & PAN Details</div>
          <div className="doc-card">🏦 Challan Payment Proof</div>
          <div className="doc-card">📄 Salary & Non-Salary Deductions</div>
          <div className="doc-card">📌 TDS Calculation Sheet</div>
        </div>
      </section>

      {/* Compliance Requirements */}
      <section className="tds-section">
        <h2>⚖️ Compliance Requirements</h2>
        <ul className="tds-compliance">
          <li>📌 File Quarterly TDS Returns</li>
          <li>📌 Issue TDS Certificates (Form 16, 16A)</li>
          <li>📌 Pay TDS Before Due Dates</li>
          <li>📌 Avoid Late Filing Penalties</li>
        </ul>
      </section>
    </div>
  );
};

export default TdsReturn;
