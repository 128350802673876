import React from "react";
import "./style.css"; // Import the CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <section className="policy-box">
        <h2>1. General</h2>
        <p>
          This Privacy Policy governs how <strong>Deenis Law Chamber</strong> collects, uses, discloses,
          and manages client data. By using our website, <strong>deenislawchamber.com</strong>,
          you agree to this Privacy Policy.
        </p>
      </section>

      <section className="policy-box">
        <h2>2. Collection of Personal Information</h2>
        <p>
          We collect personal information such as your name, email, contact details, and browsing history
          to improve our services and user experience.
        </p>
      </section>

      <section className="policy-box">
        <h2>3. Use of Cookies</h2>
        <p>
          Our website uses cookies to enhance user experience. You can choose to disable cookies through
          your browser settings.
        </p>
      </section>

      <section className="policy-box">
        <h2>4. Sharing of Personal Information</h2>
        <p>
          We may share your personal data with legal authorities if required by law. We do not sell
          or distribute your information to third parties.
        </p>
      </section>

      <section className="policy-box">
        <h2>5. Security</h2>
        <p>
          We take reasonable measures to protect your personal data from unauthorized access, disclosure,
          or destruction.
        </p>
      </section>

      <section className="policy-box">
        <h2>6. Third-Party Advertisements</h2>
        <p>
          Our website may contain third-party links. We are not responsible for their privacy practices.
          Please review their policies before providing any information.
        </p>
      </section>

      <section className="policy-box">
        <h2>7. User Consent</h2>
        <p>
          By using our website, you consent to our Privacy Policy and data collection practices.
        </p>
      </section>

      <section className="policy-box">
        <h2>8. Dispute Resolution & Jurisdiction</h2>
        <p>
          Any disputes shall be resolved through mediation or arbitration in Delhi, India. The courts
          in Delhi shall have exclusive jurisdiction.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
