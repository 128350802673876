import React, { useState, useEffect } from "react";
import "./popup.css";

const Popup = ({ title, message, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the slide-down animation when the popup mounts
    setIsVisible(true);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300); // Delay closing to match animation duration
  };

  return (
    <div className="popup-overlay">
      <div className={`popup-container ${isVisible ? "slide-down" : "slide-up"}`}>
        <h2>{title}</h2>
        <p>{message}</p>
        <button className="popup-close-btn" onClick={handleClose}>
          Agree & Close
        </button>
      </div>
    </div>
  );
};

const App = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(true); // Show popup on page load

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <div className="app">
      {isPopupVisible && (
        <Popup
          title="Website Privacy Policy"
          message="We use cookies and similar technologies to improve your experience. By using our site, you agree to our Privacy Policy."
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default Popup;
