import React from "react";
import "./trademarkrenewal.css";

const TrademarkRenewal = () => {
  return (
    <div className="trademark-container">
      <h1 className="trademark-title">Trademark Renewal</h1>
      <p className="trademark-description">
        Secure your brand identity by renewing your trademark before it expires. Timely renewal prevents legal disputes and ensures exclusive ownership rights.
      </p>

      {/* Benefits Section */}
      <section className="trademark-section">
        <h2>✅ Benefits of Trademark Renewal</h2>
        <div className="trademark-benefits">
          <div className="trademark-card">✔️ Continuous Brand Protection</div>
          <div className="trademark-card">✔️ Legal Security</div>
          <div className="trademark-card">✔️ Exclusive Ownership Rights</div>
          <div className="trademark-card">✔️ Business Credibility</div>
        </div>
      </section>

      {/* Renewal Process Section */}
      <section className="trademark-section">
        <h2>📌 Trademark Renewal Process</h2>
        <ul className="trademark-process">
          <li>📝 File TM-R Renewal Form</li>
          <li>💰 Pay the Government Fee</li>
          <li>📜 Submit Necessary Documents</li>
          <li>🔍 Verification by Authorities</li>
          <li>✅ Approval & Issuance of Renewal Certificate</li>
        </ul>
      </section>

      {/* Documents Required Section */}
      <section className="trademark-section">
        <h2>📑 Required Documents</h2>
        <div className="trademark-documents">
          <div className="doc-card">📜 Trademark Registration Certificate</div>
          <div className="doc-card">🆔 ID & Address Proof</div>
          <div className="doc-card">📝 Power of Attorney (if applicable)</div>
          <div className="doc-card">💰 Payment Receipt</div>
        </div>
      </section>

      {/* Compliance Requirements */}
      <section className="trademark-section">
        <h2>⚖️ Compliance Guidelines</h2>
        <ul className="trademark-compliance">
          <li>📌 Renew Before Expiry (Every 10 Years)</li>
          <li>📌 Avoid Extra Charges for Late Renewal</li>
          <li>📌 Use Trademark Act Compliant Symbols (™ or ®)</li>
          <li>📌 Keep Ownership & Legal Documents Updated</li>
        </ul>
      </section>
    </div>
  );
};

export default TrademarkRenewal;
