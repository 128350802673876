import React from "react";
import "./GSTReturn.css";

const GSTReturn = () => {
  return (
    <div className="gst-container">
      <h1 className="gst-title">GST Return Filing</h1>
      <p className="gst-description">
        **GST Return Filing** is a mandatory compliance for businesses registered under GST to declare their sales, purchases, and tax liability.
      </p>

      {/* Benefits Section */}
      <section className="gst-section">
        <h2>🚀 Benefits of Filing GST Returns</h2>
        <div className="gst-benefits">
          <div className="gst-card">✔️ Input Tax Credit</div>
          <div className="gst-card">✔️ Legal Compliance</div>
          <div className="gst-card">✔️ Avoid Penalties</div>
          <div className="gst-card">✔️ Business Transparency</div>
        </div>
      </section>

      {/* Filing Process Section */}
      <section className="gst-section">
        <h2>📜 GST Return Filing Process</h2>
        <ul className="gst-process">
          <li>📝 Maintain Sales & Purchase Invoices</li>
          <li>📌 Compute Tax Liability</li>
          <li>🏛️ File Monthly/Quarterly Returns (GSTR-1, GSTR-3B, etc.)</li>
          <li>📄 Verify & Submit Returns</li>
          <li>✔️ Pay GST Liability & Download Acknowledgment</li>
        </ul>
      </section>

      {/* Documents Required Section */}
      <section className="gst-section">
        <h2>📑 Required Documents</h2>
        <div className="gst-documents">
          <div className="doc-card">🧾 GSTIN Certificate</div>
          <div className="doc-card">🏦 Bank Statements</div>
          <div className="doc-card">📄 Sales & Purchase Invoices</div>
          <div className="doc-card">📌 Tax Computation Sheet</div>
        </div>
      </section>

      {/* Compliance Requirements */}
      <section className="gst-section">
        <h2>⚖️ GST Compliance Requirements</h2>
        <ul className="gst-compliance">
          <li>📌 Monthly/Quarterly GST Returns</li>
          <li>📌 Annual Return (GSTR-9)</li>
          <li>📌 GST Audit (if applicable)</li>
          <li>📌 Timely Payment of GST</li>
        </ul>
      </section>
    </div>
  );
};

export default GSTReturn;
