import React from 'react';
import { Link } from 'react-router-dom';
import './about.css';
import "../../assets/watermarked_preview.mp4"; // Import the video file

const About = () => {
    return (
        <section id="about" className="section" aria-labelledby="about-heading">
            <div className="about-section">
                <div className="about-text">
                    <h2 id="about-heading">About Us</h2>
                    <h3>
                        We Are Changing the Way People Think About Getting Online Legal & Taxation Services
                    </h3>
                    <p>
                        Mundra Legal Consultants LLP is an innovative online platform designed to simplify your legal and tax-related needs.
                        Our mission is to provide accessible, accurate, and professional services to individuals and businesses.
                    </p>
                    <p>
                        We believe in empowering our clients by providing expert guidance in legal and taxation matters through a seamless online experience.
                        Our team of certified professionals is always ready to offer personalized consultations and advice tailored to your unique situation.
                    </p>
                    <ul className="features-list">
                        <li>Timely and accurate reporting</li>
                        <li>Direct access to certified professionals</li>
                        <li>Dedicated offices for personalized support</li>
                        <li>Innovative problem-solving solutions</li>
                        <li>Transparent pricing with no hidden fees</li>
                        <li>Client-first approach for the best outcomes</li>
                    </ul>
                    <Link to="/aboutpage" aria-label="Read more about our services">
                        <button className="quote-button">Read More</button>
                    </Link>
                </div>
                <div className="about-video">
                    <video
                        className="animated-video"
                        autoPlay
                        loop
                        muted
                        playsInline
                        loading="lazy"
                    >
                        <source
                            src={require("../../assets/watermarked_preview.mp4")} // Correct path to your video
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </section>
    );
};

export default About;