import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import logo from "../../assets/logo__2_-removebg-preview.png";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setDropdownOpen(null);
  };

  const handleMouseEnter = (index) => {
    setDropdownOpen(index);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(null);
  };

  return (
    <header className="header">
      <div className="container">
        {/* Logo */}
        <div className="logo">
          <Link to="/" onClick={closeMenu}>
            <img src={logo} alt="DLC Logo" className="logo-image" />
          </Link>
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="hamburger" onClick={toggleMenu}>
          {menuOpen ? <i className="fa-solid fa-xmark"></i> : <i className="fa-solid fa-bars"></i>}
        </div>

        {/* Navigation */}
        <nav className={`nav ${menuOpen ? "active" : ""}`}>
          <ul className="nav-links">
            <li><Link to="/" onClick={closeMenu}>Home</Link></li>
            <li><Link to="/aboutpage" onClick={closeMenu}>About</Link></li>

            {/* Registration Dropdown */}
            <li 
              className="dropdown"
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={handleMouseLeave}
            >
              <span>Registration <i className="fa-solid fa-chevron-down"></i></span>
              <ul className={`dropdown-menu ${dropdownOpen === 1 ? "show" : ""}`}>
                <li><Link to="/llp-registration" onClick={closeMenu}>LLP Registration</Link></li>
                <li><Link to="/private-ltd" onClick={closeMenu}>Private Ltd Company</Link></li>
                <li><Link to="/partnership" onClick={closeMenu}>Partnership Registration</Link></li>
                <li><Link to="/opc" onClick={closeMenu}>OPC Registration</Link></li>
              </ul>
            </li>

            {/* Taxation Dropdown */}
            <li 
              className="dropdown"
              onMouseEnter={() => handleMouseEnter(2)}
              onMouseLeave={handleMouseLeave}
            >
              <span>Taxation <i className="fa-solid fa-chevron-down"></i></span>
              <ul className={`dropdown-menu ${dropdownOpen === 2 ? "show" : ""}`}>
                <li><Link to="/gst-registration" onClick={closeMenu}>GST Registration</Link></li>
                <li><Link to="/gst-return" onClick={closeMenu}>GST Return Filing</Link></li>
                <li><Link to="/income-tax" onClick={closeMenu}>Income Tax Filing</Link></li>
                <li><Link to="/tds" onClick={closeMenu}>TDS Return Filing</Link></li>
              </ul>
            </li>

          

            {/* Trademark Dropdown */}
            <li 
              className="dropdown"
              onMouseEnter={() => handleMouseEnter(3)}
              onMouseLeave={handleMouseLeave}
            >
              <span>Trademark <i className="fa-solid fa-chevron-down"></i></span>
              <ul className={`dropdown-menu ${dropdownOpen === 3 ? "show" : ""}`}>
                <li><Link to="/trademark-registration" onClick={closeMenu}>Trademark Registration</Link></li>
                <li><Link to="/trademark-renewal" onClick={closeMenu}>Trademark Renewal</Link></li>
                <li><Link to="/trademark-opposition" onClick={closeMenu}>Trademark Opposition</Link></li>
                <li><Link to="/trademark-watch" onClick={closeMenu}>Trademark Watch Service</Link></li>
              </ul>
            </li>
          
 
    <li><Link to="/web-development" onClick={closeMenu}>Website Development</Link></li>
    

              {/* Contact Page */}
              <li><Link to="/contact" onClick={closeMenu}>Contact</Link></li>

          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
