import React from "react";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./onlineconsultant.css"; // Add CSS for styling

const OnlineConsultation = () => {
  return (
    <section className="consultation-section">
      <div className="consultation-container">
        <h2>📢 Online Legal Consultation</h2>
        <p>
          Get expert legal advice from the comfort of your home. Our experienced lawyers
          provide guidance on employment matters, recovery of money, family law, 
          intellectual property, and more.
        </p>

        <ul className="consultation-list">
          <li>✅ Convenient & Time-Saving</li>
          <li>✅ Experienced Legal Professionals</li>
          <li>✅ Affordable & Transparent</li>
          <li>✅ Confidential & Secure</li>
        </ul>

        <div className="consultation-actions">
          <a href="tel:+91926558488" className="consult-btn call">
            <FaPhoneAlt /> Call Now
          </a>
          <a href="https://wa.me/919266717139" className="consult-btn whatsapp">
            <FaWhatsapp /> Chat on WhatsApp
          </a>
          <Link to="/book-consultant" className="consult-btn book">
            📅 Book Free Consultation
          </Link>
        </div>
      </div>
    </section>
  );
};

export default OnlineConsultation;
