import React from "react";
import "./TrademarkOpposition.css";

const TrademarkOpposition = () => {
  return (
    <div className="opposition-container">
      <h1 className="opposition-title">Trademark Opposition</h1>
      <p className="opposition-description">
        Trademark Opposition is a legal process where third parties can challenge a newly applied trademark before it gets registered. If someone believes your trademark infringes on their rights, they can file an opposition.
      </p>

      {/* Why File Trademark Opposition */}
      <section className="opposition-section">
        <h2>🔍 Why File a Trademark Opposition?</h2>
        <div className="opposition-benefits">
          <div className="opposition-card">✔️ Protect Your Brand Identity</div>
          <div className="opposition-card">✔️ Prevent Trademark Infringement</div>
          <div className="opposition-card">✔️ Maintain Market Uniqueness</div>
          <div className="opposition-card">✔️ Legal Safeguard Against Similar Marks</div>
        </div>
      </section>

      {/* Trademark Opposition Process */}
      <section className="opposition-section">
        <h2>📌 Steps in the Trademark Opposition Process</h2>
        <ul className="opposition-process">
          <li>📝 Filing of Trademark Application</li>
          <li>🔍 Publication in the Trademark Journal</li>
          <li>⚖️ Opposition Filing within 4 Months</li>
          <li>📜 Counter-Statement by the Applicant</li>
          <li>✅ Hearing & Final Decision</li>
        </ul>
      </section>

      {/* Documents Required */}
      <section className="opposition-section">
        <h2>📑 Required Documents</h2>
        <div className="opposition-documents">
          <div className="doc-card">📜 Copy of Trademark Application</div>
          <div className="doc-card">🆔 Identity & Address Proof</div>
          <div className="doc-card">📖 Evidence Supporting Opposition</div>
          <div className="doc-card">✍️ Power of Attorney (if filed by an attorney)</div>
        </div>
      </section>

      {/* Legal Compliance */}
      <section className="opposition-section">
        <h2>⚖️ Legal Guidelines</h2>
        <ul className="opposition-compliance">
          <li>📌 Opposition must be filed within 4 months of publication.</li>
          <li>📌 Grounds for opposition should be clearly documented.</li>
          <li>📌 The opponent must provide valid proof of prior use.</li>
          <li>📌 A counter-statement should be submitted within 2 months.</li>
        </ul>
      </section>
    </div>
  );
};

export default TrademarkOpposition;
