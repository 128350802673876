import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./service.css";

const Services = () => {
    const [selectedService, setSelectedService] = useState(null);

    const services = [
        // Registration Services
        {
            id: 1,
            image: "https://corpbiz.io/learning/wp-content/uploads/2020/02/LLP-registration-Featured-Image.jpg",
            title: "LLP Registration",
            description: "Get your Limited Liability Partnership (LLP) registered with hassle-free online services.",
            date: "March 26, 2025",
            alt: "LLP registration certificate issued to a business entity.",
            link: "/llp-registration"
        },
        {
            id: 2,
            image: "https://edplor.com/wp-content/uploads/What-is-a-Private-Limited-Company-Meaning-features-and-more.png",
            title: "Private Ltd Company",
            description: "Register your Private Limited Company effortlessly with legal compliance support.",
            date: "March 26, 2025",
            alt: "Private Ltd company registration document overview.",
            link: "/private-ltd"
        },
        {
            id: 3,
            image: "https://returnfilings.com/wp-content/uploads/2020/10/partnership-registration.jpg",
            title: "Partnership Registration",
            description: "Complete Partnership firm registration services for smooth business setup.",
            date: "March 26, 2025",
            alt: "Checklist for Partnership Registration requirements.",
            link: "/partnership"
        },
        {
            id: 4,
            image: "https://th.bing.com/th/id/OIP.MsDHQAVX4d6R4LbZAVKvZQHaEI?rs=1&pid=ImgDetMain",
            title: "OPC Registration",
            description: "Register your One Person Company (OPC) with simplified legal assistance.",
            date: "March 26, 2025",
            alt: "Certificate of incorporation for OPC business.",
            link: "/opc"
        },

        // Taxation Services
        {
            id: 5,
            image: "https://th.bing.com/th/id/OIP.fi0j1F5x1WohXB9pAL-KkAHaFh?w=640&h=477&rs=1&pid=ImgDetMain",
            title: "GST Registration",
            description: "Register your business under GST for tax compliance and smooth operations.",
            date: "March 26, 2025",
            alt: "GST registration certificate with government seal.",
            link: "/gst-registration"
        },
        {
            id: 6,
            image: "https://caindelhi.in/wp-content/uploads/2017/11/5.2-How-to-File-GST-Returns.png",
            title: "GST Return Filing",
            description: "File your GST returns on time with expert assistance to avoid penalties.",
            date: "March 26, 2025",
            alt: "GST return filing document with tax calculation details.",
            link: "/gst-return"
        },
        {
            id: 7,
            image: "https://images.indianexpress.com/2021/06/income-tax-return-itr-1200.jpg",
            title: "Income Tax Filing",
            description: "Get professional help for hassle-free Income Tax Filing and compliance.",
            date: "March 26, 2025",
            alt: "Income tax filing summary report for businesses.",
            link: "/income-tax"
        },
        {
            id: 8,
            image: "https://accubuzz.in/wp-content/uploads/2022/12/TDS-RETURN-FILING-2.jpg",
            title: "TDS Return Filing",
            description: "File TDS returns accurately and on time with professional guidance.",
            date: "March 26, 2025",
            alt: "TDS return filing form with details of deductions.",
            link: "/tds"
        },

        // Trademark Services
        {
            id: 9,
            image: "https://www.biatconsultant.com/blog/wp-content/uploads/2019/03/trademark-registration-process-and-classes-in-india.jpg",
            title: "Trademark Registration",
            description: "Protect your brand with trademark registration for exclusive rights.",
            date: "March 26, 2025",
            alt: "Trademark certificate for brand name protection.",
            link: "/trademark-registration"
        },
        {
            id: 10,
            image: "https://th.bing.com/th/id/OIP.x6PH8SqIfdVy7ZxGbVloQQAAAA?w=284&h=250&rs=1&pid=ImgDetMain",
            title: "Trademark Renewal",
            description: "Renew your trademark before expiry to maintain brand protection.",
            date: "March 26, 2025",
            alt: "Trademark renewal application form with official stamp.",
            link: "/trademark-renewal"
        },
        {
            id: 11,
            image: "https://www.complianceease.in/images/start-up/trademark-opposition.jpg",
            title: "Trademark Opposition",
            description: "File or defend against a trademark opposition with legal support.",
            date: "March 26, 2025",
            alt: "Legal notice for trademark opposition proceedings.",
            link: "/trademark-opposition"
        },
        {
            id: 12,
            image: "https://5.imimg.com/data5/BR/HF/OW/GLADMIN-10318988/selection-944-500x500.png",
            title: "Trademark Watch Service",
            description: "Monitor potential trademark infringements and protect your brand rights.",
            date: "March 26, 2025",
            alt: "Trademark watch service dashboard with alerts.",
            link: "/trademark-watch"
        }
    ];

    const handleImageClick = (service) => {
        setSelectedService(service);
    };

    const closeModal = () => {
        setSelectedService(null);
    };

    return (
        <section id="services" className="section">
            <div className="Servicescontainer">
                <h2>Our Services</h2>
            </div>
            <div className="deals-grid">
                {services.map((service) => (
                    <div className="deal-card" key={service.id} onClick={() => handleImageClick(service)}>
                        <img src={service.image} alt={service.alt} className="grid-image" />
                    </div>
                ))}
            </div>

            {selectedService && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="modal-close" onClick={closeModal}>&times;</span>
                        <img src={selectedService.image} alt={selectedService.alt} className="modal-image" />
                        <h3 className="modal-title">{selectedService.title}</h3>
                        <p className="modal-description">{selectedService.description}</p>
                        <span className="modal-date">{selectedService.date}</span>
                        <Link to={selectedService.link} className="service-link">Learn More</Link>
                    </div>
                </div>
            )}
        </section>
    );
};

export default Services;
