import React from "react";
import './style.css';
import Contact from "../../component/Contact/Contact";

const TrademarkRegistrationPage = () => {
  return (
    <div className="trademark-registration-container">
      {/* Add the image at the start */}
      <img 
        src="https://i.pinimg.com/originals/f9/12/fb/f912fb38101947b81c0d7da410a95bb7.jpg" 
        alt="Trademark Registration" 
        className="trademark-image"
      />
      
      <h1>Trademark Registration Information</h1>
      <p>
        Trademark registration is an essential step in protecting your brand,
        logo, or any symbol that identifies your products or services. It grants
        the holder exclusive rights to use the mark and prevents others from
        using a similar mark that could confuse consumers.
      </p>

      {/* Grid Section for Trademark Hearing and Related Services */}
      <div className="trademark-services-grid">
        <p>
          We provide comprehensive assistance for the following legal processes
          related to trademarks:
        </p>
        
        {/* Existing Grid Services */}
        <div className="grid-container">
         
          <div className="grid-item">
            <strong>Notice of Opposition:</strong>
            <p>Assistance in filing and responding to trademark opposition notices.</p>
            <p className="price">₹2499/-</p>
          </div>
          <div className="grid-item">
            <strong>Rectification:</strong>
            <p>Legal support for correcting errors or modifying existing trademark registrations.</p>
            <p className="price">₹2499/-</p>
          </div>
          <div className="grid-item">
            <strong>Infringement Notice:</strong>
            <p>Expert guidance for addressing cases of trademark infringement.</p>
            <p className="price">₹2499/-</p>
          </div>
        </div>

        {/* New Grid Section for Trademark Registration Stages */}
        <h2>Trademark Registration Process</h2>
        <div className="grid-container">
          <div className="grid-item">
            <strong>1. Trademark Search:</strong>
            <p>Before filing for registration, a thorough search is conducted to ensure the trademark is unique and not already registered by another entity.</p>
            <p className="price">₹1499/-</p>
          </div>
          <div className="grid-item">
            <strong>2. Application Filing:</strong>
            <p>Filing the application with the appropriate trademark office, including submitting the required documents and paying fees.</p>
            <p>Including gov. fee</p>
            <p className="price">₹5999/-</p>
          </div>
          <div className="grid-item">
            <strong>3. Trademark Examination / Reply</strong>
            <p>Once filed, the trademark application is examined to ensure it meets all legal requirements. If accepted, the trademark will be published for opposition.</p>
            <p className="price">₹1999/-</p>
          </div>
          <div className="grid-item">
            <strong>4. Trademark Hearing / Acceptance</strong>
            <p>If any opposition is raised during the publication stage, a hearing may take place where both parties present their cases. If accepted, the trademark proceeds to registration.</p>
            <p className="price">₹3999/-</p>
          </div>
          
        </div>
      </div>

      <h2>What is a Trademark?</h2>
      <p>
        A trademark is a unique symbol, word, or other indicator used to
        distinguish your goods or services from others. It helps in establishing
        a brand identity and provides legal protection against imitation or
        unauthorized use by competitors.
      </p>

      <h2>Trademark Classifications</h2>
      <p>
        Trademarks are categorized into 45 different classes under the Nice Classification System. Below is the complete list of trademark classes:
      </p>
      {/* List of Classes */}
      <h3>Goods (Classes 1–34):</h3>
      <ul>
        <li><strong>Class 1:</strong> Chemicals used in industry and science.</li>
        <li><strong>Class 2:</strong> Paints, varnishes, lacquers, and anti-corrosive preparations.</li>
        <li><strong>Class 3:</strong> Cleaning, polishing, scouring, and abrasive preparations.</li>
        <li><strong>Class 4:</strong> Industrial oils and greases, lubricants, fuels.</li>
        <li><strong>Class 5:</strong> Pharmaceuticals and medical preparations.</li>
        <li><strong>Class 6:</strong> Common metals and their alloys.</li>
        <li><strong>Class 7:</strong> Machines and machine tools.</li>
        <li><strong>Class 8:</strong> Hand tools and implements.</li>
        <li><strong>Class 9:</strong> Scientific, photographic, and electronic apparatus.</li>
        <li><strong>Class 10:</strong> Medical and veterinary apparatus.</li>
        <li><strong>Class 11:</strong> Apparatus for lighting, heating, cooking, and refrigeration.</li>
        <li><strong>Class 12:</strong> Vehicles and apparatus for locomotion.</li>
        <li><strong>Class 13:</strong> Firearms, ammunition, and explosives.</li>
        <li><strong>Class 14:</strong> Precious metals, jewelry, and timepieces.</li>
        <li><strong>Class 15:</strong> Musical instruments.</li>
        <li><strong>Class 16:</strong> Paper, cardboard, and printed materials.</li>
        <li><strong>Class 17:</strong> Rubber, gutta-percha, and insulating materials.</li>
        <li><strong>Class 18:</strong> Leather and imitations of leather.</li>
        <li><strong>Class 19:</strong> Building materials (non-metallic).</li>
        <li><strong>Class 20:</strong> Furniture and articles of wood, cork, and plastic.</li>
        <li><strong>Class 21:</strong> Household or kitchen utensils.</li>
        <li><strong>Class 22:</strong> Ropes, strings, nets, and sails.</li>
        <li><strong>Class 23:</strong> Yarns and threads for textile use.</li>
        <li><strong>Class 24:</strong> Textiles and textile goods.</li>
        <li><strong>Class 25:</strong> Clothing, footwear, headgear.</li>
        <li><strong>Class 26:</strong> Lace, embroidery, and haberdashery.</li>
        <li><strong>Class 27:</strong> Carpets, rugs, mats, and linoleum.</li>
        <li><strong>Class 28:</strong> Games, toys, and sporting equipment.</li>
        <li><strong>Class 29:</strong> Meat, fish, poultry, and dairy products.</li>
        <li><strong>Class 30:</strong> Coffee, tea, sugar, and pastries.</li>
        <li><strong>Class 31:</strong> Agricultural, horticultural, and forestry products.</li>
        <li><strong>Class 32:</strong> Beers, mineral waters, and soft drinks.</li>
        <li><strong>Class 33:</strong> Alcoholic beverages (except beers).</li>
        <li><strong>Class 34:</strong> Tobacco and smokers’ articles.</li>
      </ul>
      <h3>Services (Classes 35–45):</h3>
      <ul>
        <li><strong>Class 35:</strong> Business management, advertising, and consultancy.</li>
        <li><strong>Class 36:</strong> Financial and insurance services.</li>
        <li><strong>Class 37:</strong> Construction and repair services.</li>
        <li><strong>Class 38:</strong> Telecommunications.</li>
        <li><strong>Class 39:</strong> Transport and storage.</li>
        <li><strong>Class 40:</strong> Treatment of materials (e.g., custom manufacturing).</li>
        <li><strong>Class 41:</strong> Education, training, and entertainment.</li>
        <li><strong>Class 42:</strong> Scientific and technological services, IT services.</li>
        <li><strong>Class 43:</strong> Food and drink services (e.g., hotels, restaurants).</li>
        <li><strong>Class 44:</strong> Medical, beauty, and agricultural services.</li>
        <li><strong>Class 45:</strong> Legal, security, and social services.</li>
      </ul>

      <p>
        For further assistance and details, you can reach out to the nearest
        trademark office or consult with a legal professional specializing in
        intellectual property rights.
      </p>
      <Contact />
    </div>
  );
};

export default TrademarkRegistrationPage;




