import React from "react";
import "./Partnership.css";

const PartnershipRegistration = () => {
  return (
    <div className="partnership-container">
      <h1>Partnership Firm Registration</h1>
      <p>
        A **Partnership Firm** is a popular form of business where two or more people manage and operate a 
        business according to the terms of the partnership agreement.
      </p>

      <section className="partnership-section">
        <h2>📌 Why Choose a Partnership Firm?</h2>
        <div className="benefits-grid">
          <div className="benefit-card">✔ Easy to Form</div>
          <div className="benefit-card">✔ Shared Responsibilities</div>
          <div className="benefit-card">✔ Minimal Compliance</div>
          <div className="benefit-card">✔ Low Registration Cost</div>
        </div>
      </section>

      <section className="partnership-section">
        <h2>📝 Registration Process</h2>
        <ol className="process-list">
          <li>📌 Choose a Unique Name for the Firm</li>
          <li>📌 Create a Partnership Deed</li>
          <li>📌 Get the Deed Notarized</li>
          <li>📌 Apply for PAN & TAN</li>
          <li>📌 Open a Business Bank Account</li>
          <li>📌 Register with the Registrar of Firms (if required)</li>
        </ol>
      </section>

      <section className="partnership-section">
        <h2>📄 Required Documents</h2>
        <div className="doc-grid">
          <div className="doc-card">🆔 Partners' PAN & Aadhar</div>
          <div className="doc-card">📜 Partnership Deed</div>
          <div className="doc-card">🏠 Business Address Proof</div>
          <div className="doc-card">📄 GST Registration (if applicable)</div>
        </div>
      </section>

      <section className="partnership-section">
        <h2>⚖ Compliance Requirements</h2>
        <ul className="compliance-list">
          <li>📌 Filing of Income Tax Returns</li>
          <li>📌 GST Filing (if applicable)</li>
          <li>📌 Renewal of Licenses</li>
          <li>📌 Maintenance of Accounts</li>
        </ul>
      </section>
    </div>
  );
};

export default PartnershipRegistration;
