import React from "react";
import "./privateltd.css";

const PvtLtdRegistration = () => {
  return (
    <div className="pvt-container">
      <h1>Private Limited Company Registration</h1>
      <p>
        A **Private Limited Company** is a popular business structure in India, offering limited liability 
        to shareholders while maintaining flexibility in operations.
      </p>

      <section className="pvt-section">
        <h2>📌 Why Choose a Private Limited Company?</h2>
        <div className="benefits-grid">
          <div className="benefit-card">✔ Limited Liability Protection</div>
          <div className="benefit-card">✔ Separate Legal Entity</div>
          <div className="benefit-card">✔ Easy Fundraising</div>
          <div className="benefit-card">✔ Perpetual Existence</div>
        </div>
      </section>

      <section className="pvt-section">
        <h2>📝 Registration Process</h2>
        <ol className="process-list">
          <li>📌 Obtain Digital Signature Certificate (DSC)</li>
          <li>📌 Apply for Director Identification Number (DIN)</li>
          <li>📌 Reserve Company Name (SPICe+ Form)</li>
          <li>📌 Draft & File Memorandum and Articles of Association (MOA & AOA)</li>
          <li>📌 Obtain Incorporation Certificate</li>
          <li>📌 Apply for PAN & TAN</li>
          <li>📌 Open a Bank Account</li>
        </ol>
      </section>

      <section className="pvt-section">
        <h2>📄 Required Documents</h2>
        <div className="doc-grid">
          <div className="doc-card">🆔 Director's PAN & Aadhar</div>
          <div className="doc-card">🏠 Address Proof</div>
          <div className="doc-card">📜 MOA & AOA</div>
          <div className="doc-card">📄 Digital Signature Certificate</div>
        </div>
      </section>

      <section className="pvt-section">
        <h2>⚖ Compliance Requirements</h2>
        <ul className="compliance-list">
          <li>📌 Annual ROC Filings</li>
          <li>📌 Income Tax Filing</li>
          <li>📌 GST Filing (if applicable)</li>
          <li>📌 Director KYC Filing</li>
        </ul>
      </section>
    </div>
  );
};

export default PvtLtdRegistration;
