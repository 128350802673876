import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./component/Header/header";
import Home from "./component/Home/home";
import About from "./component/About/about";
import Services from "./component/Services/service";
import Registration from "./component/Registration/registration";

import BookConsultant from "./component/BookConsultant/BookConsultant";
import  Testimonials from "./component/Testimonials/Testimonials";
import Contact from "./component/Contact/Contact";
import Footer from "./component/Footer/footer";
import Popup from "./component/PopUp/popup";
import "@fortawesome/fontawesome-free/css/all.min.css";

import FeaturePage from "./page/Feature/feature";

import BirthCertificatePage from './page/Birth/birth';
import SuccessionCertificatePage from "./page/succession/succession";
import TrademarkRegistrationPage from "./page/trademark/trademark";
import CompanyRegistrationPage from './page/company/company';

import FSSAILicense from './page/Fssai/fassi';
import CorporateCompliance from './page/corporate/corporate';
import GSTRegistration from "./page/GST/gst";
import CompanyRegistration from "./page/companyllp/companyllp";

import TrafficChallan from "./page/Traffic/Traffic";
import DeathCertificate from "./page/Death/death";
import NameChange from "./page/Name/namechange";
import SaleDeed from "./page/saledeed/sale";
import LegalHeirCertificate from "./page/Legal/legalheir";

import EmploymentMatters from "./page/employment/employment";
import  MoneyRecovery from "./page/Recovery/recovery";

import DrugPage from "./page/DrugLicense/DrugLicense";
import AboutDeenisLawChamber from "./page/aboutpage/aboutpage"

import ResignationInfo from "./page/regpage/regpage";

import BusinessLicense from "./page/businesslicense/businesslicense";
import ITServicesPage from "./page/it/it";
import  BlogPage from "./page/Blog/blog";
import PressReleasePage from "./page/PressNews/pressnews";
import  AddInformation from './page/addinformation/addinfomation';

import  CopyrightIPR from './page/CopyWrite/copyright';
import  LMPCCertificate from './page/LMPC Certificate/index';
import EPRCertificate from './page/EPR Certificate/index';
import TermsConditions from './page/tandc/tandc';
import PrivacyPolicy from './page/privacyandpolicy/privacy';
import LLPRegistration from "./page/LLPresg/LLPreg";
import PvtLtdRegistration from "./page/privateltdcompany/privateltd";
import PartnershipRegistration from "./page/Partnership/Partnership";
import  OPCRegistration from "./page/OPC/opc";
import GSTReturn from "./page/GSTReturn/GSTReturn";
import IncomeTax from "./page/IncomeTax/IncomeTax";
import TdsReturn from "./page/TDS/TDS";
import TrademarkRenewal from "./page/trademarkrenewal/trademarkrenewal";
import TrademarkOpposition from "./page/TrademarkOpposition/TrademarkOpposition";
import TrademarkWatch from "./page/Trademakewatch/Trademakewatch";
import OnlineConsultation from "./component/onlineconsultant/onlineconsultant";


function App() {
  const [isPopupVisible, setIsPopupVisible] = useState(true);

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <Router>
      {/* Show the popup at the top level */}
      {isPopupVisible && (
        <Popup
          title="Disclaimer"
          message="As per the rules of the Bar Council of India, law firms are not permitted to solicit work and advertise. By clicking the 'Agree' button and accessing this website. It should not be interpreted as soliciting or advertisement. The firm is not liable for any consequence of any action taken by the user relying on material/information provided under this website. In cases where the user has any legal issues, he/she in all cases must seek independent legal advice"
          onClose={handleClosePopup}
        />
      )}

      {/* Header stays persistent across all routes */}
      <Header />

      <Routes>
        {/* Define individual routes */}
        <Route
          path="/"
          element={
            <>
              <Home />
              <About />
              <Services />
              < Testimonials/>
              <Registration />
              <OnlineConsultation/>
             
              <Contact />
            </>
          }
        />
       
 <Route path="/llp-registration" element={<LLPRegistration/>}/>
 <Route path="/private-ltd" element={<PvtLtdRegistration/>}/>
 <Route path="/partnership" element={<PartnershipRegistration/>}/>
 <Route path="/opc" element={< OPCRegistration/>}/>
 <Route path="/gst-registration" element={<GSTRegistration/>}/>
 <Route path="/trademark-registration" element={<TrademarkRegistrationPage/>}/>
 <Route path="/gst-return" element={<GSTReturn/>}/>
 <Route path="/income-tax" element={<IncomeTax/>}/>
 <Route path="/tds" element={<TdsReturn/>}/>
 <Route path="/trademark-renewal" element={<TrademarkRenewal/>}/>
 <Route path="/trademark-opposition" element={<TrademarkOpposition/>}/>
 <Route path="/trademark-watch" element={<TrademarkWatch/>}/>

       





        <Route path="/aboutpage" element={<AboutDeenisLawChamber/>}/>
      
        <Route path="/regpage" element={<ResignationInfo/>}/>
      
        <Route path="/web-development" element={<ITServicesPage/>}/>
        <Route path ="/contact" element={< AddInformation/>}/>
      
         <Route path="/copyrightipr" element={< CopyrightIPR/>}/>
         <Route path="/lmpc-certificate" element={< LMPCCertificate/>}/>
         <Route path="/epr-certificate" element={<EPRCertificate/>}/>
 

       
      
        
        <Route path="/book-consultant" element={<BookConsultant />} />
        <Route path="/feature" element={<FeaturePage/>}/>
      
        <Route path="/birth" element={<BirthCertificatePage/>}/>
        <Route path="/succession" element={<SuccessionCertificatePage/>}/>
       
        <Route path="/company" element={<CompanyRegistrationPage/>}/>
      
        <Route path="/fassi" element={<FSSAILicense/>}/>
        <Route path= "/corporate" element= {<CorporateCompliance/>}/>
       
        <Route path="/companyllp" element={<CompanyRegistration/>}/>
     
        <Route path="/Traffic" element={<TrafficChallan/>}/>
        <Route path="death" element={<DeathCertificate/>}/>
    <Route path="/namechange" element={<NameChange/>}/>
    <Route path="/sale" element={<SaleDeed/>}/>
    <Route path="/legalheir" element={<LegalHeirCertificate/>}/>
   
    <Route path="/employment" element={<EmploymentMatters/>}/>
    <Route path="/recovery" element={< MoneyRecovery/>}/>
  
   
    <Route path="/DrugLicense" element={<DrugPage/>}/>
    <Route path="/business-license" element={<BusinessLicense/>}/>
    <Route path="/blog" element={< BlogPage/>}/>
    <Route path="/pressnews" element={< PressReleasePage/>}/>
    <Route path="/tandc" element={< TermsConditions/>}/>
    <Route path="/privacy" element={< PrivacyPolicy/>}/>

      </Routes>

      {/* Footer stays persistent across all routes */}
      <Footer />
    </Router>
  );
}

export default App;
