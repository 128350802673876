import React from "react";
import "./Trademakewatch.css";

const TrademarkWatch = () => {
  return (
    <div className="watch-container">
      <h1 className="watch-title">Trademark Watch</h1>
      <p className="watch-description">
        Trademark Watch is a monitoring service that helps businesses track and protect their registered trademarks. It prevents unauthorized use by keeping an eye on newly filed trademarks that may conflict with yours.
      </p>

      {/* Benefits Section */}
      <section className="watch-section">
        <h2>🌍 Why Use Trademark Watch?</h2>
        <div className="watch-benefits">
          <div className="watch-card">🚀 Stay Ahead of Competitors</div>
          <div className="watch-card">🔍 Identify Similar Trademarks Early</div>
          <div className="watch-card">⚖️ Prevent Legal Conflicts</div>
          <div className="watch-card">📢 Protect Brand Reputation</div>
        </div>
      </section>

      {/* Trademark Watch Process */}
      <section className="watch-section">
        <h2>📌 How Does It Work?</h2>
        <ul className="watch-process">
          <li>📊 Regular Monitoring of Trademark Databases</li>
          <li>🔍 Identifying Potential Conflicts</li>
          <li>📩 Alert Notifications on Similar Trademarks</li>
          <li>🛡️ Assistance in Filing Opposition</li>
        </ul>
      </section>

      {/* Pricing Plans */}
      <section className="watch-section">
        <h2>💰 Our Pricing Plans</h2>
        <div className="pricing-table">
          <div className="pricing-card">
            <h3>Basic</h3>
            <p>✅ Monthly Monitoring</p>
            <p>✅ Email Notifications</p>
            <p>Rs 10000 /-year</p>
          </div>
          <div className="pricing-card premium">
            <h3>Premium</h3>
            <p>✅ Weekly Monitoring</p>
            <p>✅ Detailed Reports</p>
            <p>✅ Legal Assistance</p>
            <p>Rs 50000/-year</p>
          </div>
          <div className="pricing-card">
            <h3>Enterprise</h3>
            <p>✅ Daily Monitoring</p>
            <p>✅ Custom Alerts</p>
            <p>✅ Dedicated Support</p>
            <p>Rs 25000/-year</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TrademarkWatch;
