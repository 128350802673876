import React, { useState } from 'react';
import './home.css';

const Home = () => {
    // Sample data for search functionality
    const services = [
        "Family Law",
        "Corporate Law",
        "Criminal Defense",
        "Property Disputes",
        "Civil Litigation",
        "Alternative Dispute Resolution",
        "Taxation Services",
        "Business Transactions",
        "Mediation and Arbitration",
        "Legal Consultations",
    ];

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredResults, setFilteredResults] = useState([]);

    const handleSearch = () => {
        if (searchQuery.trim() === "") {
            setFilteredResults([]);
            return;
        }
        const results = services.filter((service) =>
            service.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredResults(results);
    };

    return (
        <>
            {/* Main Content */}
            <section id="home" className="section" aria-labelledby="home-heading">
                <div className="Homecontent">
                    {/* Left side: Text and Search Box */}
                    <div className="text-container">
                        <h1 id="home-heading">Welcome to Deenis Law Chamber</h1>
                        <p>
                            At <strong>Deenis Law Chamber</strong>, we specialize in providing comprehensive legal services with a client-focused approach. 
                            Backed by years of expertise in various fields of law, our team of skilled professionals is committed to assisting individuals, 
                            businesses, and organizations in navigating complex legal challenges with confidence and clarity.
                        </p>
                        <h2>Our Mission</h2>
                        <p>
                            Our mission is to deliver <strong>top-notch legal advice</strong> and representation tailored to each client's unique needs. 
                            We aim to build long-lasting relationships based on trust, transparency, and exceptional results.
                        </p>
                        
                        {/* Service Search Box */}
                        <div className="search-box">
                            <input 
                                type="text" 
                                placeholder="Search for legal services..." 
                                className="search-input" 
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <button className="search-button" onClick={handleSearch}>
                                Search
                            </button>
                        </div>

                        {/* Search Results */}
                        {filteredResults.length > 0 && (
                            <div className="search-results">
                                <ul>
                                    {filteredResults.map((result, index) => (
                                        <li key={index}>{result}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {filteredResults.length === 0 && searchQuery && (
                            <div className="no-results">No services found</div>
                        )}
                    </div>

                    {/* Right side: Image with curved corners */}
                    <div className="image-container">
                        <img 
                            src="https://www.expertlawfirm.com/wp-content/uploads/2023/07/AdobeStock_571762531-1024x585.jpg" 
                            alt=" Deenis Law Chamber"  
                            className="image-curved"
                            loading="lazy"
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
