import React from "react";
import "./style.css";

const LMPCCertificate = () => {
  return (
    <div className="lmpc-container">
      <h1 className="lmpc-title">LMPC Certificate</h1>
      <p className="lmpc-description">
        The Legal Metrology Packaged Commodities (LMPC) certificate ensures that businesses comply with the packaging and labeling regulations under the Legal Metrology Act.
      </p>
      <h2 className="lmpc-subtitle">Why is LMPC Certification Important?</h2>
      <ul className="lmpc-list">
        <li>Ensures compliance with government regulations.</li>
        <li>Protects consumer rights.</li>
        <li>Prevents misleading packaging and labeling.</li>
        <li>Mandatory for importers and manufacturers dealing with packaged goods.</li>
      </ul>
      <h2 className="lmpc-subtitle">How to Apply?</h2>
      <p className="lmpc-description">
        Businesses need to submit an application with necessary documents, including business registration, product details, and compliance reports. The process includes verification by the legal metrology department.
      </p>
      <h2 className="lmpc-subtitle">Required Documents</h2>
      <ul className="lmpc-list">
        <li>Business Registration Certificate</li>
        <li>Manufacturer/Importer License</li>
        <li>Product Specifications and Label Samples</li>
        <li>Compliance Report</li>
      </ul>
    </div>
  );
};

export default LMPCCertificate;
