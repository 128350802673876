import React from "react";
import "./IncomeTax.css";

const IncomeTax = () => {
  return (
    <div className="tax-container">
      <h1 className="tax-title">Income Tax Filing</h1>
      <p className="tax-description">
        Filing **Income Tax Returns (ITR)** is an essential responsibility for individuals and businesses to report their income and pay taxes as per government regulations.
      </p>

      {/* Benefits Section */}
      <section className="tax-section">
        <h2>🚀 Benefits of Filing Income Tax</h2>
        <div className="tax-benefits">
          <div className="tax-card">✔️ Legal Compliance</div>
          <div className="tax-card">✔️ Avail Tax Refunds</div>
          <div className="tax-card">✔️ Easy Loan Approval</div>
          <div className="tax-card">✔️ Avoid Penalties</div>
        </div>
      </section>

      {/* Filing Process Section */}
      <section className="tax-section">
        <h2>📜 Income Tax Filing Process</h2>
        <ul className="tax-process">
          <li>📝 Gather Income Documents (Salary, Business, etc.)</li>
          <li>📌 Compute Total Income & Deductions</li>
          <li>🏛️ Choose the Right ITR Form</li>
          <li>📄 File Return Online via Income Tax Portal</li>
          <li>✔️ Verify Return & Receive Acknowledgment</li>
        </ul>
      </section>

      {/* Documents Required Section */}
      <section className="tax-section">
        <h2>📑 Required Documents</h2>
        <div className="tax-documents">
          <div className="doc-card">🧾 PAN Card</div>
          <div className="doc-card">🏦 Bank Statements</div>
          <div className="doc-card">📄 Form 16 (Salary Slip)</div>
          <div className="doc-card">📌 Investment Proofs (Tax Deductions)</div>
        </div>
      </section>

      {/* Compliance Requirements */}
      <section className="tax-section">
        <h2>⚖️ Tax Compliance Requirements</h2>
        <ul className="tax-compliance">
          <li>📌 File ITR Annually Before the Due Date</li>
          <li>📌 Maintain Financial Records for Audit</li>
          <li>📌 Pay Advance Tax (if applicable)</li>
          <li>📌 Report High-Value Transactions</li>
        </ul>
      </section>
    </div>
  );
};

export default IncomeTax;
